import React from 'react'
import { images } from 'config/images'

export const MobAppDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        Mobile app development company dedicated to{' '}
        <span className="highlight-head">building memorable experiences</span>
      </>
    ),
    para: (
      <>
        Top-notch mobile apps from an{' '}
        <span className="color-through">award-winning</span> development company
        with a focus on your business profitability
      </>
    ),
    formCTA: 'Let’s discuss your project',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Leading companies trust us to deliver{' '}
        <span className="highlight-head">impactful mobile apps</span>
      </>
    ),
    buildAppPara: (
      <>
        We boast a talent pool of native and cross-platform specialists and{' '}
        <span className="color-through">industry-specific experts</span> with
        experience in building mHealth apps, mobile payment apps, and m-commerce
        apps.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our services are <br />
        <span className="highlight-head">result-oriented</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Native Mobile Apps Development',
        serviceParagraph: (
          <>
            Native <strong>B2B and consumer app</strong> development services
            allow you to utilize the inherent power of iOS and Android platforms
            with secure and performant apps.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Hybrid Cross-platform App Development',
        serviceParagraph: (
          <>
            Utilize the power of{' '}
            <strong>React Native, Flutter, and Xamarin</strong> to develop
            powerful apps for multiple platforms while sharing the same code
            base.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Business Analysis & Consulting',
        serviceParagraph: (
          <>
            Whether you are looking to develop business apps or B2C apps to
            service customers, our consultants can help you{' '}
            <strong>define the right tech</strong> plan.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Business Apps and API Integration',
        serviceParagraph: (
          <>
            We <strong>integrate your mobile app(s)</strong> with services you
            are already using and extend their use cases for your employees and
            consumers.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Custom App Solutions ',
        serviceParagraph: (
          <>
            <strong>Customized mobile solutions</strong> to drive your
            operations. For example, a CMS to organize and publish content, all
            in a simple screen.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Quality',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Mobile Device Management',
        serviceParagraph: (
          <>
            Our MDM consulting helps customers{' '}
            <strong>simplify app distribution.</strong> Secure, manage and
            monitor app inventory remotely to protect your business.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We collaborate with you to understand your business needs, define
            the user experience, build a <strong>tech delivery roadmap</strong>{' '}
            and align the processes with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our creative technologists turn your{' '}
            <strong>ideas into apps</strong> with high ROI and retention rates.
            We use design thinking, user research, and usability testing
            throughout to ensure the mobile app appeals to your audience.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice</strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.{' '}
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Mobile apps</span> portfolio
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/mobile-application-security-data-vulnerabilities/',
        featurecardTitle:
          'Mobile Application Security: Checklist for Data Security and Vulnerabilities',
      },
      {
        blogLink:
          'https://www.simform.com/offline-mobile-app-architectures-ios-android/',
        featurecardTitle:
          'Offline Mobile App Architectures for iOS and Android Apps',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-performance/',
        featurecardTitle: 'How to Improve your Mobile App’s Performance?',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactKellyPowell,
    personImg2x: images.contactKellyPowell2x,
    personName: 'Kelly Powell',
    personDesignation:
      'Helping businesses to accelerate growth by creating modern applications.',
    personEmail: 'kelly@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
